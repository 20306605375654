import "./App.css";
import cx from "classnames";

function App() {
  const isDesktop = window.innerWidth > 768;

  return (
    <div className="App">
      <section className="Hero">
        <header className="App-header">
          <img
            src={"/assets/images/logo.jpg"}
            className={cx("App-logo", "mt40")}
            alt="Logo chalet du parc"
          />
        </header>
        <div className="Hero-content">
          <h1 className="Hero-title">
            Manger
            <br />
            Boire
            <br />
            Découvrir
            <br />
            Apprendre
            <br />
            Partager
            <br />
          </h1>
        </div>
        <p className="Credits">Crédit photo: Filippo Bolognese</p>
      </section>
      <section className="Section">
        <div className="Section-text">
          <h2>Retrouvons nous !</h2>
          <p>
            Depuis des générations, le Chalet du Parc de la Tête d'Or fait
            partie de l'histoire intime des Lyonnaises et des Lyonnais, évoquant
            à la fois souvenirs d'enfance, mémoires familiales, et moments
            d'exception autour de la culture et de la gastronomie. Construit en
            1894, reconstruit en 1963, il est fermé depuis plus de 10 ans.
          </p>
          {!isDesktop && (
            <div className="Section-image-container">
              <img
                src={"/assets/images/18.jpg"}
                alt="Vue du lac"
                className="Section-image"
              />
              <img
                src={"/assets/images/31.jpg"}
                alt="Vue du lac"
                className="Section-icon"
              />
            </div>
          )}
          {isDesktop && (
            <div className="Desktop-section-images-wrapper">
              <div className="Desktop-section-image-container">
                <img
                  src={"/assets/images/18.jpg"}
                  alt="Vue du lac"
                  className="Section-image"
                />
                <img
                  src={"/assets/images/31.jpg"}
                  alt="Icone de renard"
                  className="Section-icon"
                />
              </div>{" "}
              <div className="Desktop-section-image-container">
                <img
                  src={"/assets/images/3.jpg"}
                  alt="Des mains qui manipulent des plantes pour faire un herbier"
                  className="Section-image"
                />
                <img
                  src={"/assets/images/34.jpg"}
                  alt="Icone abstraite"
                  className="Section-icon"
                />
              </div>
              <div className="Desktop-section-image-container">
                <img
                  src={"/assets/images/16.jpg"}
                  alt="Nénuphar flottant sur l'eau"
                  className="Section-image"
                />
                <img
                  src={"/assets/images/33.jpg"}
                  alt="Icone de feuille"
                  className="Section-icon"
                />
              </div>
            </div>
          )}
          <h2>Retrouvez-nous</h2>
          <p className="Section-text">
            Le moment de se retrouver est venu : le Chalet du Parc rouvrira ses
            portes à l'automne 2026 avec un restaurant-bar, des expositions et
            ateliers sur la protection de l'environnement, des espaces de
            privatisation et d'évènements, des résidences d'artistes...
          </p>
          <p className="Section-text">
            Le Chalet du Parc, ni tout à fait le même, ni tout à fait un autre
            retrouve ainsi sa vocation historique de lieu de rassemblement et de
            partage pour toutes et tous à travers un projet qui répond aux
            grands défis du 21e siècle.
          </p>
          <p className="Section-text">
            Ce projet est celui de toute une ville qui se retrouve autour de son
            patrimoine naturel, architectural et culturel, mais aussi de ses
            valeurs, de sa vision de l'avenir et de son envie d'entreprendre au
            service de l'intérêt général.
          </p>
        </div>
      </section>
      <section className="Invest">
        <h2 className="Invest-title">
          <img
            src={"/assets/images/32.jpg"}
            alt="Icone de soleil"
            className="Invest-icon"
          />
          Investir dans l'avenir
        </h2>
        <div className="Invest-text">
          <h3>Des enfants,</h3>
          <h3>des associations,</h3>
          <h3>des familles</h3>
          <h3>et des salariés d'entreprise.</h3>
        </div>
        <div className="Invest-images-container">
          <p className="Invest-description">
            Le projet s'inscrit dans le cadre d'un bail emphytéotique de 50 ans
            avec la Ville de Lyon, garantissant un modèle économique équilibré
            et une vision pérenne pour ce lieu d'exception.
          </p>
          {isDesktop && (
            <div className="Desktop-invest-secondary-description">
              <p style={{ fontWeight: "bold" }} className="Desktop-invest-text">
                L'investissement total de 7,4 millions d'euros témoigne de
                l'ambition portée pour ce lieu :
              </p>
              <p className="Desktop-invest-text">
                → 5,9 M€ de travaux financés par le groupement
                Youse-Evolem-Banque des Territoires.
              </p>
              <p className="Desktop-invest-text">
                → 1,5 M€ d'aménagements intérieurs dont le financement est
                partagé entre le groupement et les exploitants : La Fabuleuse
                Cantine et la Fondation GoodPlanet.
              </p>
            </div>
          )}
          <div className="Invest-images-track-overlay" />
          <div className="Invest-images-track">
            <img
              src={"/assets/images/7.jpg"}
              alt="Vue d'une exposition photographique"
              className="Invest-image"
            />
            <img
              src={"/assets/images/2.jpg"}
              alt="Renardeau"
              className="Invest-image"
            />
            <img
              src={"/assets/images/25.jpg"}
              alt="Des enfants qui découvrent la nature"
              className="Invest-image"
            />
            <img
              src={"/assets/images/10.jpg"}
              alt="Icone de plante"
              className="Invest-image"
            />
            <img
              src={"/assets/images/7.jpg"}
              alt="Vue d'une exposition photographique"
              className="Invest-image"
            />
            <img
              src={"/assets/images/2.jpg"}
              alt="Renardeau"
              className="Invest-image"
            />
            <img
              src={"/assets/images/25.jpg"}
              alt="Des enfants qui découvrent la nature"
              className="Invest-image"
            />
            <img
              src={"/assets/images/10.jpg"}
              alt="Icone de plante"
              className="Invest-image"
            />
          </div>
        </div>
        {!isDesktop && (
          <div>
            <p style={{ fontWeight: "bold" }} className="Desktop-invest-text">
              L'investissement total de 7,4 millions d'euros témoigne de
              l'ambition portée pour ce lieu :
            </p>
            <p className="Desktop-invest-text">
              → 5,9 M€ de travaux financés par le groupement Youse-Evolem-Banque
              des Territoires.
            </p>
            <p
              style={{ paddingBottom: "8rem" }}
              className="Desktop-invest-text"
            >
              → 1,5 M€ d'aménagements intérieurs dont le financement est partagé
              entre le groupement et les exploitants : La Fabuleuse Cantine et
              la Fondation GoodPlanet.
            </p>
          </div>
        )}
      </section>
      <section className={cx("Section", "Innovate")}>
        {!isDesktop && (
          <>
            <div className="Section-image-container">
              <img
                src={"/assets/images/9.jpg"}
                alt="Vue du lac"
                className="Section-image"
              />
              <img
                src={"/assets/images/34.jpg"}
                alt="Icone abstraite"
                className="Section-icon"
              />
            </div>
            <div className="Section-text">
              <h2>Innover pour l'environnement</h2>
              <p>
                Le nouveau Chalet incarnera l'excellence environnementale en
                devenant un bâtiment à énergie positive, alliant respect du
                patrimoine historique et responsabilité climatique. Nous visons
                à être EnerPHit, le standard le plus strict et le plus complet
                au monde pour les rénovations énergétiques :
              </p>
            </div>
            <div className="Tags">
              <p className="Tag">Une pompe à chaleur eau-eau</p>
              <p className="Tag">
                Une isolation adaptée au bâtiment historique
              </p>
              <p className="Tag">Des panneaux photovoltaïques</p>
              <p className="Tag">Des chauffe-eau solaires intégrés</p>
              <p className="Tag">Recyclage des eaux usées des cuisines</p>
              <p className="Tag">Ventilation naturelle par brasseur d'air</p>
            </div>
          </>
        )}
        {isDesktop && (
          <>
            <div className="Section-text">
              <h2>Innover pour l'environnement</h2>
              <p>
                Le nouveau Chalet incarnera l'excellence environnementale en
                devenant un bâtiment à énergie positive, alliant respect du
                patrimoine historique et responsabilité climatique. Nous visons
                à être EnerPHit, le standard le plus strict et le plus complet
                au monde pour les rénovations énergétiques :
              </p>
            </div>
            <div className="Desktop-innovate-wrapper">
              <div className="Tags">
                <p className="Tag">Une pompe à chaleur eau-eau</p>
                <p className="Tag">
                  Une isolation adaptée au bâtiment historique
                </p>
                <p className="Tag">Des panneaux photovoltaïques</p>
                <p className="Tag">Des chauffe-eau solaires intégrés</p>
                <p className="Tag">Recyclage des eaux usées des cuisines</p>
                <p className="Tag">Ventilation naturelle par brasseur d'air</p>
              </div>
              <div className="Section-image-container">
                <img
                  src={"/assets/images/9.jpg"}
                  alt="Vue du lac"
                  className="Section-image"
                />
                <img
                  src={"/assets/images/34.jpg"}
                  alt="Icone abstraite"
                  className="Section-icon"
                />
              </div>
            </div>
          </>
        )}
      </section>
      <section className={cx("Section", "Gastronomy")}>
        {!isDesktop && (
          <>
            <div className="Section-image-container">
              <img
                src={"/assets/images/27.jpg"}
                alt="Fruits et légumes"
                className="Section-image"
              />
              <img
                src={"/assets/images/33.jpg"}
                alt="Icone abstraite"
                className="Section-icon"
              />
            </div>
            <div className="Section-text">
              <h2>
                Créer une nouvelle offre de gastronomie, de culture et
                d'éducation
              </h2>
              <p className="Gastronomy-text">
                <strong>Un concept de restauration innovant</strong> qui fait
                déjà ses preuves à Lyon, Saint-Étienne et La Rochelle, alliant
                gastronomie locale et lutte contre le gaspillage alimentaire.
              </p>
              <div className="Gastronomy-logo-container">
                <img
                  src={"/assets/images/fabuleusecantine-logo.png"}
                  alt="Logo fabuleuse cantine"
                  className="Gastronomy-logo"
                />
              </div>
              <p className="Gastronomy-text">
                <strong>
                  Des expositions interactives gratuites sur les enjeux
                  environnementaux,
                </strong>{" "}
                portées par une fondation de renommée internationale.
              </p>
              <div className="Gastronomy-logo-container">
                <img
                  src={"/assets/images/goodplanet-logo.png"}
                  alt="Logo good planet"
                  className="Gastronomy-logo"
                />
              </div>
              <p className="Gastronomy-text">
                <strong>Une résidence artistique trimestrielle unique</strong>{" "}
                faisant du Chalet un carrefour de création et d'expression
                artistique.
              </p>
              <div className="Gastronomy-logo-container">
                <img
                  src={"/assets/images/gutemberg-logo.png"}
                  alt="Logo gutemberg"
                  className="Gastronomy-logo"
                />
              </div>
            </div>
          </>
        )}
        {isDesktop && (
          <>
            <div className="Section-text">
              <h2>
                Créer une nouvelle offre de gastronomie, de culture et
                d'éducation
              </h2>
              <div className="Section-wrapper">
                <div className="Section-image-container">
                  <img
                    src={"/assets/images/27.jpg"}
                    alt="Fruits et légumes"
                    className="Section-image"
                  />
                  <img
                    src={"/assets/images/33.jpg"}
                    alt="Icone de feuille"
                    className="Section-icon"
                  />
                </div>
                <div className="Desktop-gastronomy-wrapper">
                  <p className="Gastronomy-text">.</p>
                  <p className="Gastronomy-text">
                    <strong>Un concept de restauration innovant</strong> qui
                    fait déjà ses preuves à Lyon, Saint-Étienne et La Rochelle,
                    alliant gastronomie locale et lutte contre le gaspillage
                    alimentaire.
                  </p>
                  <div className="Gastronomy-logo-container">
                    <img
                      src={"/assets/images/fabuleusecantine-logo.png"}
                      alt="Logo fabuleuse cantine"
                      className="Gastronomy-logo"
                    />
                  </div>
                  <p className="Gastronomy-text">.</p>
                  <p className="Gastronomy-text">
                    <strong>
                      Des expositions interactives gratuites sur les enjeux
                      environnementaux,
                    </strong>{" "}
                    portées par une fondation de renommée internationale.
                  </p>
                  <div className="Gastronomy-logo-container">
                    <img
                      src={"/assets/images/goodplanet-logo.png"}
                      alt="Logo good planet"
                      className="Gastronomy-logo"
                    />
                  </div>
                  <p className="Gastronomy-text">.</p>
                  <p className="Gastronomy-text">
                    <strong>
                      Une résidence artistique trimestrielle unique
                    </strong>{" "}
                    faisant du Chalet un carrefour de création et d'expression
                    artistique.
                  </p>
                  <div className="Gastronomy-logo-container">
                    <img
                      src={"/assets/images/gutemberg-logo.png"}
                      alt="Logo gutemberg"
                      className="Gastronomy-logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
      <section className={cx("Section", "Future")}>
        {!isDesktop && (
          <>
            <div className="Section-image-container">
              <img
                src={"/assets/images/13.jpg"}
                alt="Salle de conférence"
                className="Section-image"
              />
              <img
                src={"/assets/images/30.jpg"}
                alt="Icone abstraite"
                className="Section-icon"
              />
            </div>
            <div className="Section-text">
              <h2>Penser le futur avec le monde économique</h2>
              <h4>Le chalet proposera des espaces événementiels premium :</h4>
              <table>
                <tr>
                  <td>
                    <img
                      src={"/assets/images/smiley.png"}
                      alt="Icone sourire"
                      className="Future-icon"
                    />
                  </td>
                  <td>Salles modulables jusqu'à 200 personnes</td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={"/assets/images/sun.png"}
                      alt="Icone soleil"
                      className="Future-icon"
                    />
                  </td>
                  <td>Prestations sur-mesure</td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={"/assets/images/smiley-full.png"}
                      alt="Icone sourire"
                      className="Future-icon"
                    />
                  </td>
                  <td>Programmation culturelle et environnementale unique</td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={"/assets/images/heart.png"}
                      alt="Icone coeur"
                      className="Future-icon"
                    />
                  </td>
                  <td>Cadre historique d'exception</td>
                </tr>
              </table>
            </div>
          </>
        )}
        {isDesktop && (
          <>
            <div className="Section-text">
              <h2>Penser le futur avec le monde économique</h2>
              <h4>Le chalet proposera des espaces événementiels premium :</h4>
              <div className="Section-wrapper">
                <table>
                  <tr>
                    <td>
                      <img
                        src={"/assets/images/smiley.png"}
                        alt="Icone sourire"
                        className="Future-icon"
                      />
                    </td>
                    <td>Salles modulables jusqu'à 200 personnes</td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        src={"/assets/images/sun.png"}
                        alt="Icone soleil"
                        className="Future-icon"
                      />
                    </td>
                    <td>Prestations sur-mesure</td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        src={"/assets/images/smiley-full.png"}
                        alt="Icone sourire"
                        className="Future-icon"
                      />
                    </td>
                    <td>Programmation culturelle et environnementale unique</td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        src={"/assets/images/heart.png"}
                        alt="Icone coeur"
                        className="Future-icon"
                      />
                    </td>
                    <td>Cadre historique d'exception</td>
                  </tr>
                </table>
                <div className="Section-image-container">
                  <img
                    src={"/assets/images/13.jpg"}
                    alt="Salle de conférence"
                    className="Section-image"
                  />
                  <img
                    src={"/assets/images/30.jpg"}
                    alt="Icone abstraite"
                    className="Section-icon"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </section>
      <section className={cx("Section", "Chalet")}>
        {!isDesktop && (
          <div className="Section-text">
            <h2>Chalet du parc</h2>
            <p>
              Ouvert 7/7, été comme hiver, avec des menus abordables et de
              saison.
            </p>
            <div className="Section-image-container">
              <img
                src={"/assets/images/icons-panel.png"}
                alt="Panneaux d'icones"
                className="Chalet-image"
              />
            </div>
            <p className="Chalet-text">
              <span className="Chalet-span">599 m²</span>
              <br /> dédiés à un restaurant antigaspi et lieu de vie.
            </p>
            <p className="Chalet-text">
              <span className="Chalet-span">511 m²</span>
              <br /> consacrés à l'éducation sur les grands sujets
              environnementaux : forêts, océans, alimentation ...
            </p>
            <p className="Chalet-text">
              <span className="Chalet-span">600 m²</span>
              <br /> d'espaces gratuits à visiter, et 1000 m² de terrasses.
            </p>
            <p className="Chalet-text">
              <span className="Chalet-span">300 000</span>
              <br /> visiteurs & usagers du chalet.
            </p>
            <p className="Chalet-text">
              <span className="Chalet-span">140</span>
              <br /> couverts disponibles (hors terrasses)
            </p>
            <p className="Chalet-text">
              <span className="Chalet-span">12</span>
              <br /> employés dédiés aux activités de sensibilisation et au
              bien-manger.
            </p>
          </div>
        )}
        {isDesktop && (
          <>
            <div className="Section-text">
              <h2>Chalet du parc</h2>
              <p>
                Ouvert 7/7, été comme hiver, avec des menus abordables et de
                saison.
              </p>
            </div>
            <div className="Desktop-chalet-wrapper">
              <div className="Section-image-container">
                <img
                  src={"/assets/images/icons-panel.png"}
                  alt="Panneaux d'icones"
                  className="Chalet-image"
                />
              </div>
              <p className="Chalet-text">
                <span className="Chalet-span">599 m²</span>
                <br /> dédiés à un restaurant antigaspi et lieu de vie.
              </p>
              <p className="Chalet-text">
                <span className="Chalet-span">511 m²</span>
                <br /> consacrés à l'éducation sur les grands sujets
                environnementaux : forêts, océans, alimentation ...
              </p>
              <p className="Chalet-text">
                <span className="Chalet-span">600 m²</span>
                <br /> d'espaces gratuits à visiter, et 1000 m² de terrasses.
              </p>
              <p className="Chalet-text">
                <span className="Chalet-span">300 000</span>
                <br /> visiteurs & usagers du chalet.
              </p>
              <p className="Chalet-text">
                <span className="Chalet-span">140</span>
                <br /> couverts disponibles (hors terrasses)
              </p>
              <p className="Chalet-text">
                <span className="Chalet-span">12</span>
                <br /> employés dédiés aux activités de sensibilisation et au
                bien-manger.
              </p>
            </div>
          </>
        )}
      </section>
      <footer className="Footer">
        <img
          src={"/assets/images/logo.jpg"}
          className="App-logo"
          alt="Logo chalet du parc"
        />
        <p>©{new Date().getFullYear()} Chalet du Parc - Tous droits réservés</p>
        <p className={cx("Credits", "mt16")}>
          Crédits photos: Filippo Bolognese, Kevin Dolmaire, La Fabuleuse
          Cantine, Fondation GoodPlanet. Unsplash: Johnny Cohen, Alexx Cooper,
          Gabriel Jimenez, Jamie Taylor, Jean Wimmerlin. Freepix.
        </p>
      </footer>
    </div>
  );
}

export default App;
